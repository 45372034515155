/* You can add global styles to this file, and also import other style files */
@import '~bootstrap-icons/font/bootstrap-icons.css';

.appContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appHeader {
  flex-shrink: 0;
  flex-grow: 0;
  height: 72px;
}

.appContent {
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

.appContent.scrollbarYonly:has(.account-detail),
.appContent.scrollbarYonly:has(.contact-detail),
.appContent.scrollbarYonly:has(.job-detail) {
  margin-right: 2rem;
  padding-right: 0;
}

.appFooter {
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  width: 100%;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

.close {
  font-size: 1rem;
  outline: none !important;
}

.navbar {
  padding-bottom: 0;
  padding-top: 0;
}

.table td {
  padding-top: 4px;
  padding-bottom: 4px;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
    no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

/* span.ngb-highlight {
  background-color: lightpink;
} */

.profile-name {
  color: #fff;
}

.scrollbarYonly {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollbarXonly {
  overflow-y: hidden;
  overflow-x: auto;
}

.scrollbarXY {
  overflow-y: auto;
  overflow-x: auto;
}

.scrollbarYonly::-webkit-scrollbar-track,
.scrollbarXonly::-webkit-scrollbar-track,
.scrollbarXY::-webkit-scrollbar-track {
  border: 1px solid rgba(00, 123, 255, 1);
  border-radius: 7px;
  background-color: rgba(00, 123, 255, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
}

.scrollbarYonly::-webkit-scrollbar,
.scrollbarXonly::-webkit-scrollbar,
.scrollbarXY::-webkit-scrollbar {
  width: 14px;
  border-radius: 7px;
  padding-left: 1rem;
}

.scrollbarYonly::-webkit-scrollbar-thumb,
.scrollbarXonly::-webkit-scrollbar-thumb,
.scrollbarXY::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(00, 123, 255, 0.6);
}

.scrollbarYonly::-webkit-scrollbar-thumb:hover,
.scrollbarXonly::-webkit-scrollbar-thumb:hover,
.scrollbarXY::-webkit-scrollbar-thumb:hover {
  border-radius: 7px;
  background-color: rgba(00, 123, 255, 0.8);
}

.scrollbarYonly::-webkit-scrollbar-thumb:active,
.scrollbarXonly::-webkit-scrollbar-thumb:active,
.scrollbarXY::-webkit-scrollbar-thumb:active {
  border-radius: 7px;
  background-color: rgba(00, 123, 255, 1);
}

.mainTable > .tableList {
  height: calc(100vh - 240px);
}

.selectionsTable > .tableList {
  height: calc(100vh - 281px);
}

.selectionsDDTable > .tableList {
  height: calc(100vh - 288px);
}

.tableList {
  padding-right: 1rem;
  border-top: 1px solid rgba(00, 123, 255, 0.8);
  border-bottom: 1px solid rgba(00, 123, 255, 0.8);
}

.tableList::-webkit-scrollbar-track {
  margin-top: 40px;
}

.tableList > table > thead th {
  border-top: none;
}

.tableList > table > thead > tr {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.tablePageContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.tablePageContainer.tablePage,
.tablePageContainer.tablePageDD {
  width: 100%;
  margin: auto;
}

.tooltip.show {
  opacity: 1;
}

.pdf-tooltip .tooltip-inner {
  background-color: #343a40;
  font-size: 1.1rem;
}

.pdf-tooltip .arrow::before {
  border-top-color: #343a40;
}

.todo-feature {
  background-color: lightgray;
  border-radius: 3px;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}
